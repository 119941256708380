var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-toolbar",
        { staticClass: "pa-4", attrs: { flat: "" } },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.typeTab,
                callback: function ($$v) {
                  _vm.typeTab = $$v
                },
                expression: "typeTab",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary", outlined: "" },
                },
                [_c("v-tab", [_vm._v(" User ")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary", outlined: "" },
                },
                [_c("v-tab", [_vm._v(" Company ")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary", outlined: "" },
                },
                [_c("v-tab", [_vm._v(" Renewal ")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary", outlined: "" },
                },
                [_c("v-tab", [_vm._v(" Sales ")])],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary", outlined: "" },
                },
                [_c("v-tab", [_vm._v(" Revenue ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.typeTab,
            callback: function ($$v) {
              _vm.typeTab = $$v
            },
            expression: "typeTab",
          },
        },
        [
          _c("v-tab-item", [_c("PageActiveUsersAnalysis"), _c("br")], 1),
          _c("v-tab-item", [_c("PageActiveCompaniesAnalysis"), _c("br")], 1),
          _c("v-tab-item", [_c("PageRenewalAnalysis"), _c("br")], 1),
          _c("v-tab-item", [_c("PageSalesAnalysis"), _c("br")], 1),
          _c("v-tab-item", [_c("PageRevenueAnalysis"), _c("br")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }