import { render, staticRenderFns } from "./PageEventSalesTable.vue?vue&type=template&id=04625214&"
import script from "./PageEventSalesTable.vue?vue&type=script&lang=js&"
export * from "./PageEventSalesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCol,VContainer,VDataTable,VRow,VSkeletonLoader,VTextField,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04625214')) {
      api.createRecord('04625214', component.options)
    } else {
      api.reload('04625214', component.options)
    }
    module.hot.accept("./PageEventSalesTable.vue?vue&type=template&id=04625214&", function () {
      api.rerender('04625214', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/analysis/PageEventSalesTable.vue"
export default component.exports