var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-5", attrs: { cols: "12", sm: "12" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "justify-center text-h5 font-weight-bold text-center",
                },
                [_vm._v(" Renewal By Month ")]
              ),
              _c(
                "div",
                { staticClass: "px-5" },
                [_c("PageRenewalTable", { attrs: { year: _vm.year } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }