var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          items: _vm.formattedTopicSales,
                          headers: _vm.topicSalesHeader,
                          search: _vm.searchResult,
                          dense: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-toolbar",
                                        {
                                          staticClass: "pa-3",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              filled: "",
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              label: "Search",
                                            },
                                            model: {
                                              value: _vm.searchResult,
                                              callback: function ($$v) {
                                                _vm.searchResult = $$v
                                              },
                                              expression: "searchResult",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2759062199
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }