var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-5", attrs: { cols: "12", sm: "12" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "justify-center text-h5 font-weight-bold text-center",
                },
                [_vm._v(" Active Company By Month ")]
              ),
              _c("br"),
              _c("v-select", {
                staticClass: "py-0 my-0",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: "Year",
                  items: _vm.yearOptions,
                },
                model: {
                  value: _vm.year,
                  callback: function ($$v) {
                    _vm.year = $$v
                  },
                  expression: "year",
                },
              }),
              _c("PageActiveCompanyByMonthChart", {
                attrs: { year: _vm.year },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }