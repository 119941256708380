import { render, staticRenderFns } from "./PageSalesByMonthChart.vue?vue&type=template&id=24b66759&"
import script from "./PageSalesByMonthChart.vue?vue&type=script&lang=js&"
export * from "./PageSalesByMonthChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VContainer,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24b66759')) {
      api.createRecord('24b66759', component.options)
    } else {
      api.reload('24b66759', component.options)
    }
    module.hot.accept("./PageSalesByMonthChart.vue?vue&type=template&id=24b66759&", function () {
      api.rerender('24b66759', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/analysis/PageSalesByMonthChart.vue"
export default component.exports