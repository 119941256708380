var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-card", { attrs: { flat: "" } }, [
        _c(
          "div",
          [
            _c(
              "v-card-text",
              { staticClass: "d-flex justify-center align-item " },
              [
                _vm.api.isLoading
                  ? _c("v-progress-circular", {
                      staticStyle: {
                        "align-items": "center",
                        position: "absolute",
                      },
                      attrs: { size: 50, color: "primary", indeterminate: "" },
                    })
                  : _vm._e(),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.api.isLoading,
                      expression: "!api.isLoading",
                    },
                  ],
                  ref: "myChart",
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }